import React from 'react';
import { DataGrid, DataGridProps, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { Typography } from '@mui/material';
import { ITransaction } from '../models';
import { formatCurrency } from '../util/formatCurrency';
import { formatDate } from '../util/formatDate';

const dateValueGetter = (params: GridValueGetterParams) => {
    const data = formatDate(params.row.date, 'en-US', {
        dateStyle: 'short',
        timeStyle: 'short'
    });
    return data;
};

const amountValueGetter = (params: GridValueGetterParams) => {
    const data = formatCurrency(
        params.row.amount,
        params.row.location?.merchant?.currency,
        params.row.location?.merchant?.locale
    );
    return data;
};

const columns: GridColDef[] = [
    {
        field: 'location',
        headerName: 'Location',
        minWidth: 200,
        flex: 1
    },
    {
        field: 'description',
        headerName: 'Type',
        flex: 0.5
    },
    {
        field: 'date',
        headerName: 'Date',
        valueGetter: dateValueGetter,
        flex: 0.5
    },
    {
        field: 'amount',
        headerName: 'Amount',
        valueGetter: amountValueGetter,
        flex: 0.5
    }
];

export type TransactionGridProps = Partial<DataGridProps> & {
    rows: ITransaction[];
};

function TransactionGridPure({ rows, ...rest }: TransactionGridProps) {
    if (rows.length === 0) {
        return <Typography variant="h6">No transactions found</Typography>;
    }

    const modifiedRows = rows.map((row, index) => ({
        ...row,
        id: index,
        location: row.location?.name_full || 'Unknown'
    }));

    return (
        <div style={{ flexGrow: 1, width: '100%' }}>
            <DataGrid autoHeight {...rest} rows={modifiedRows} columns={columns} />
        </div>
    );
}

export const TransactionGrid = React.memo(TransactionGridPure);
