import React from 'react';
import { DataGrid, DataGridProps, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { Typography } from '@mui/material';
import { ILoyaltyBalance } from '../models';
import { formatCurrency } from '../util/formatCurrency';

const rewardBalanceValueGetter = (params: GridValueGetterParams) => {
    const data = formatCurrency(
        params.row.dollars,
        params.row.loyalty_program?.currency,
        params.row.loyalty_program?.locale
    );
    return data;
};

const columns: GridColDef[] = [
    {
        field: 'program',
        headerName: 'Loyalty Program',
        minWidth: 200,
        flex: 1
    },
    {
        field: 'points',
        headerName: 'Point Balance',
        type: 'number',
        flex: 0.5
    },
    {
        field: 'dollars',
        headerName: 'Reward Balance',
        valueGetter: rewardBalanceValueGetter,
        flex: 0.5
    }
];

export type LoyaltyBalanceGridProps = Partial<DataGridProps> & {
    rows: ILoyaltyBalance[];
};

function LoyaltyBalanceGridPure({ rows, ...rest }: LoyaltyBalanceGridProps) {
    if (rows.length === 0) {
        return <Typography variant="h6">No loyalty balances found</Typography>;
    }

    const modifiedRows = rows.map((row, index) => ({
        ...row,
        id: index,
        program: row.loyalty_program?.name || 'Unknown'
    }));

    return (
        <div style={{ flexGrow: 1, width: '100%' }}>
            <DataGrid autoHeight {...rest} rows={modifiedRows} columns={columns} />
        </div>
    );
}

export const LoyaltyBalanceGrid = React.memo(LoyaltyBalanceGridPure);
