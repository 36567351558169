import React from 'react';
import { Button, CircularProgress, styled } from '@mui/material';
import { ButtonProps } from '@mui/material/Button';
import clsx from 'clsx';

const PREFIX = 'LoadingButton';

const classes = {
    buttonProgress: `${PREFIX}-buttonProgress`,
    gutterBottom: `${PREFIX}-gutterBottom`,
    fullWidth: `${PREFIX}-fullWidth`
};

const Root = styled('div')(({ theme }) => ({
    position: 'relative',
    [`& .${classes.buttonProgress}`]: {
        color: theme.palette.primary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    },
    [`& .${classes.gutterBottom}`]: {
        marginBottom: theme.spacing(2)
    },
    [`&.${classes.fullWidth}`]: {
        width: '100%'
    }
}));

export type LoadingButtonProps = ButtonProps & {
    loading?: boolean;
    gutterBottom?: boolean;
};

function LoadingButtonPure({ loading, gutterBottom, onClick, ...rest }: LoadingButtonProps) {
    return (
        <Root
            className={clsx({
                [classes.gutterBottom]: gutterBottom,
                [classes.fullWidth]: rest.fullWidth
            })}
        >
            <Button {...rest} disabled={loading || rest.disabled} onClick={loading ? undefined : onClick} />
            {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
        </Root>
    );
}

export const LoadingButton = React.memo(LoadingButtonPure);
