import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { APP_ROUTES, ROUTES } from 'src/routes';
import { Layout } from 'src/components/base/Layout';

export function AppRoutes() {
    return (
        <Routes>
            {APP_ROUTES.map(route => {
                const element = <Layout>{route.component}</Layout>;

                return <Route key={route.path} path={route.path} element={element} />;
            })}
            <Route path="*" element={<Navigate to={ROUTES.HOME} />} />
        </Routes>
    );
}
