import { createTheme } from '@mui/material';

export const theme = createTheme({
    components: {
        MuiInputBase: {
            styleOverrides: {
                root: {
                    borderRadius: '3px !important'
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none'
                }
            }
        }
    },
    typography: {
        fontFamily: ['Helvetica Neue', 'sans-serif'].join(','),

        h6: {
            fontSize: '14px'
        },
        h5: {
            fontSize: '20px'
        },
        h4: {
            fontSize: '26px'
        },
        h3: {
            fontSize: '32px'
        },
        h2: {
            fontSize: '36px'
        },
        h1: {
            fontSize: '40px'
        }
    }
});
