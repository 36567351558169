import { Home } from 'src/pages/Home';
import { ReactNode } from 'react';

interface IRoute {
    path: string;
    component: JSX.Element | ReactNode;
}

export const ROUTES = {
    HOME: '/'
};

export const APP_ROUTES: IRoute[] = [
    {
        path: ROUTES.HOME,
        component: <Home />
    }
];
