import { WithChildren } from 'src/models/types';
import React from 'react';
import { Box } from '@mui/material';
import { MAX_APP_CONTENT_WIDTH } from 'src/consts';

export function Layout({ children }: WithChildren) {
    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            sx={{ height: '100vh', background: 'white' }}
        >
            <Box maxWidth={MAX_APP_CONTENT_WIDTH} p={2} width="100%">
                {children}
            </Box>
        </Box>
    );
}
