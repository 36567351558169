import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

const powercardApi = axios.create({
    baseURL: process.env.REACT_APP_POWERCARD_API
});

const requestHandler = (request: AxiosRequestConfig) => {
    request.headers = {
        ...request.headers,
        Accept: 'application/json'
    };

    return request;
};

const responseHandler = (response: AxiosResponse) => response;

const errorHandler = (error: AxiosError) => Promise.reject(error);

powercardApi.interceptors.request.use(
    request => requestHandler(request),
    error => errorHandler(error)
);

powercardApi.interceptors.response.use(
    response => responseHandler(response),
    error => errorHandler(error)
);

export { powercardApi };
