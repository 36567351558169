import { Box, Typography, TextField } from '@mui/material';
import React from 'react';
import { powercardApi } from 'src/api';
import { useSnackbar } from 'notistack';
import { GENERAL_ERROR_MESSAGE } from 'src/consts';
import { ICardData } from 'src/models';
import { LoadingButton } from 'src/components/base/LoadingButton';
import { CardContext, ICardContext } from '../App';
import BalanceSearch from '../assets/balance-search.png';
import { GiftBalanceGrid } from '../components/GiftBalanceGrid';
import { LoyaltyBalanceGrid } from '../components/LoyaltyBalanceGrid';
import { TransactionGrid } from '../components/TransactionGrid';

export function Home() {
    const { cardData, setCardData } = React.useContext<ICardContext>(CardContext);
    const [cardNumber, setCardNumber] = React.useState<string>('');
    const [loading, setLoading] = React.useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const handleSearch = () => {
        if (!cardNumber) {
            return;
        }
        setLoading(true);
        powercardApi
            .post<ICardData>('cards/find', {
                card_number: cardNumber
            })
            .then(res => {
                setCardData!(res);
            })
            .catch(err => {
                const response = err?.response?.data;

                enqueueSnackbar(response ? `${response.message} (${response.code})` : GENERAL_ERROR_MESSAGE, {
                    variant: 'error'
                });
            })
            .finally(() => {
                setLoading(false);
            });
    };
    const onCardNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCardNumber(e.target.value);
    };
    const onCardNumberKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            handleSearch();
        }
    };

    return (
        <Box pb={20}>
            <Box mt={2}>
                <Typography variant="h1" align="center">
                    <img src={BalanceSearch} height="100px" alt="logo" />
                </Typography>
            </Box>
            <Box mt={2}>
                <Typography variant="h4" fontWeight="bold" align="center">
                    Check Your Card Balance
                </Typography>
            </Box>
            <Box mt={2} display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                <TextField
                    value={cardNumber}
                    onChange={onCardNumberChange}
                    onKeyDown={onCardNumberKeyDown}
                    size="small"
                    fullWidth
                    variant="outlined"
                    label="Card Number"
                    title="Card Number"
                    sx={{
                        mr: 1.25
                    }}
                />
                <LoadingButton
                    loading={loading}
                    onClick={handleSearch}
                    color="primary"
                    variant="contained"
                    size="large"
                    disabled={!cardNumber}
                >
                    <Typography fontWeight={700} color="common.white">
                        Submit
                    </Typography>
                </LoadingButton>
            </Box>

            {cardData.data && (
                <Box
                    mt={2}
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-between"
                    alignItems="center"
                    gap={2}
                >
                    <GiftBalanceGrid rows={cardData.data.gift} pageSize={5} />
                    <LoyaltyBalanceGrid rows={cardData.data.loyalty} pageSize={5} />
                    <TransactionGrid rows={cardData.data.transactions} pageSize={25} />
                </Box>
            )}
        </Box>
    );
}
