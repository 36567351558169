import React from 'react';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { BrowserRouter as Router } from 'react-router-dom';
import { AppRoutes } from 'src/components/base/AppRoutes';
import { SnackbarProvider } from 'notistack';
import { INITIAL_ASYNC_DATA } from 'src/consts';
import { IAsyncData, ICardData } from 'src/models';
import { theme } from './config/theme';

export interface ICardContext {
    cardData: IAsyncData<ICardData>;
    setCardData?: (value: ICardContext['cardData']) => void;
}

export const CardContext = React.createContext<ICardContext>({ cardData: INITIAL_ASYNC_DATA });

function App() {
    const [cardData, setCardData] = React.useState<IAsyncData<ICardData>>(INITIAL_ASYNC_DATA);

    return (
        // eslint-disable-next-line react/jsx-no-constructed-context-values
        <CardContext.Provider value={{ cardData, setCardData }}>
            <SnackbarProvider
                maxSnack={3}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
            >
                <Router>
                    <ThemeProvider theme={theme}>
                        <CssBaseline />
                        <AppRoutes />
                    </ThemeProvider>
                </Router>
            </SnackbarProvider>
        </CardContext.Provider>
    );
}

export default App;
