import { IAsyncData } from 'src/models';

export const MAX_APP_CONTENT_WIDTH = 1024;
export const GENERAL_ERROR_MESSAGE = 'Something went wrong, please try again.';

export const INITIAL_ASYNC_DATA: IAsyncData<any> = {
    data: null,
    loading: false,
    error: undefined
};
