import React from 'react';
import { DataGrid, DataGridProps, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { Typography } from '@mui/material';
import { IGiftBalance } from '../models';
import { formatCurrency } from '../util/formatCurrency';

const giftBalanceValueGetter = (params: GridValueGetterParams) => {
    const data = formatCurrency(
        params.row.dollars,
        params.row.gift_program?.currency,
        params.row.gift_program?.locale
    );
    return data;
};

const columns: GridColDef[] = [
    {
        field: 'program',
        headerName: 'Gift Program',
        minWidth: 200,
        flex: 1
    },
    {
        field: 'dollars',
        headerName: 'Gift Balance',
        valueGetter: giftBalanceValueGetter,
        flex: 0.5
    }
];

export type GiftBalanceGridProps = Partial<DataGridProps> & {
    rows: IGiftBalance[];
};

function GiftBalanceGridPure({ rows, ...rest }: GiftBalanceGridProps) {
    if (rows.length === 0) {
        return <Typography variant="h6">No gift balances found</Typography>;
    }

    const modifiedRows = rows.map((row, index) => ({
        ...row,
        id: index,
        program: row.gift_program?.name || 'Unknown'
    }));

    return (
        <div style={{ flexGrow: 1, width: '100%' }}>
            <DataGrid autoHeight {...rest} rows={modifiedRows} columns={columns} />
        </div>
    );
}

export const GiftBalanceGrid = React.memo(GiftBalanceGridPure);
